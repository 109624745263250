import { Fragment, useEffect, useRef, useState } from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';

import { XCircleIcon } from '@heroicons/react/24/outline';
import { Dialog, DialogContent, DialogOverlay } from '@reach/dialog';
import { motion, useScroll } from 'framer-motion';

import { CloseIcon, Logo, MenuIcon, NavGithubLogo } from '@/components/icons/ElementIcons';
import InfoBannerModal from '@/components/shared/InfoBannerModal';
import { KomodoWalletBtnNav } from '@/components/shared/KomodoWalletBtnNav';
import MobileSuperNavItem from '@/components/shared/MobileSuperNavItem';
import SuperNavDropdown from '@/components/shared/SuperNavDropdown';

import { inter } from '@/styles/fonts';

import superNavData from '@/data/SuperNavData.json';

const langDropdownData = {
  title: 'En',
  items: [
    {
      title: 'English',
      link: '/en/',
    },
    {
      title: 'Pусский',
      link: 'https://blog.komodoplatform.com/ru/',
    },
    {
      title: 'Español',
      link: 'https://blog.komodoplatform.com/es/',
    },
  ],
};

export const SuperNav = ({ fixed }: { fixed: boolean }) => {
  const { scrollYProgress } = useScroll();
  let router = useRouter();
  let [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const mobileNavRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const closeMobileNavModalIfClickOutside = (event: MouseEvent) => {
      if (mobileNavRef.current && !mobileNavRef.current.contains(event.target as Node)) {
        closeMobileNavModal();
      }
    };
    document.addEventListener('mousedown', closeMobileNavModalIfClickOutside);

    return () => {
      document.removeEventListener('mousedown', closeMobileNavModalIfClickOutside);
    };
  }, []);

  function closeMobileNavModal() {
    setIsMobileNavOpen(false);
  }
  function openMobileNavModal() {
    setIsMobileNavOpen(true);
  }

  return (
    <>
      {/* <InfoBannerModal /> */}
      <header
        className={`${
          fixed ? 'fixed' : 'absolute'
        } firefox:bg-opacity-90 top-0 z-[10000] w-full bg-opacity-30 px-4 text-white backdrop-blur-lg backdrop-filter transition-all xs:px-8 lg:px-0 ${router.asPath.includes('/flyer/') ? 'hidden' : ''}`}
        id='nav'
      >
        {(router.asPath.includes('/blog/') || router.asPath.includes('/academy/')) &&
        !/^\/en\/(blog|academy)\/?$/.test(router.asPath) &&
        !/^\/en\/(blog|academy)\/\d+\/?$/.test(router.asPath) &&
        !router.asPath.includes('/tags/') &&
        !router.asPath.includes('/tag/') &&
        !router.asPath.includes('/authors/') &&
        !router.asPath.includes('/author/') ? (
          <motion.div
            className='fixed left-0 right-0 top-0 z-[20000] h-[7px] origin-left bg-[#4986ea] transition-all duration-300'
            style={{ scaleX: scrollYProgress }}
          />
        ) : null}
        <div className='firefox:bg-opacity-40 fake-div-for-tw z-0 z-[7000] mb-4 hidden h-[23px] h-[37px] h-[67px] table-auto space-x-2 border-gray-500 bg-black bg-opacity-90 pt-7'></div>
        <div
          className='container relative mx-auto py-4 transition-all md:py-2'
          id='nav-padding-div'
        >
          <nav className='relative flex items-center justify-between lg:grid lg:grid-cols-24 lg:justify-center lg:gap-0 lg:px-8 xl:px-0'>
            <div className='hidden xl:col-span-2 xl:block'></div>
            <div className='flex w-full md:col-span-5 md:items-center'>
              <div className='flex w-full justify-between md:w-auto'>
                <Link href='/en/' aria-label='Komodo logo' className=''>
                  <Logo />
                </Link>
              </div>
            </div>
            <div className='mt-3 hidden items-center py-2 lg:col-span-15 lg:mx-auto lg:mt-0 lg:flex lg:py-0 lg:text-base xl:col-span-10 xl:text-lg'>
              <Link
                href='/en/developer/'
                className={`mt-1 hidden px-2 py-2 font-medium lg:block ${router.asPath.includes('/developer/') ? 'trustless-gradient hover:opacity-80' : 'text-white hover:text-gray-300'}`}
              >
                Developer
              </Link>
              <Link
                href='/en/community/'
                className={`mt-1 hidden px-2 py-2 font-medium lg:block ${router.asPath.includes('/community/') ? 'trustless-gradient hover:opacity-80' : 'text-white hover:text-gray-300'}`}
              >
                Community
              </Link>
              {/* <Link
              href='#'
              className='hidden px-2 py-2 mt-1 font-medium text-white hover:text-gray-300 lg:block'
            >
              White Paper
            </Link> */}
              <Link
                href='/en/blog/'
                className='mt-1 hidden px-2 py-2 font-medium text-white hover:text-gray-300 lg:block'
              >
                News
              </Link>
              <Link
                href='/en/wallet/'
                className='mt-1 hidden px-2 py-2 font-medium text-white hover:text-gray-300 lg:block'
              >
                Downloads
              </Link>
              {/* {superNavData.map((item: any, index: number) => {
              return <SuperNavDropdown dropDownData={item} key={index} />;
            })} */}
              {/* <Link
              href="/en/contact/"
              className="hidden px-2 py-2 mt-1 font-medium text-white hover:text-gray-300 lg:block"
            >
              Contact
            </Link> */}
            </div>

            <div className='hidden justify-end md:col-span-4 md:items-center lg:col-span-4 lg:flex xl:col-span-5'>
              <div className='ml-12 flex flex-row items-center'>
                <Link href='https://github.com/komodoplatform/' target='_blank'>
                  <NavGithubLogo className='mr-4 h-8 w-8 duration-300 hover:-translate-y-2' />
                </Link>
                <KomodoWalletBtnNav />
              </div>
            </div>

            <div className='hidden xl:col-span-2 xl:block'></div>

            {/* <div className="flex flex-row items-center ml-2 lg:hidden">
            {superNavData.map((item: any, index: number) => {
              return <SuperNavDropdown dropDownData={item} key={index} />;
            })}
            <Link
              href="/en/contact/"
              className="hidden px-2 py-2 mt-1 font-medium text-white hover:text-gray-300 lg:block"
            >
              Contact
            </Link>
          </div> */}
            <div className='block lg:hidden'>
              <button
                type='button'
                onClick={openMobileNavModal}
                className='relative'
                aria-label='Open navigation'
              >
                <MenuIcon className='h-9 w-9 stroke-slate-500' />
              </button>
              <Dialog isOpen={isMobileNavOpen} onDismiss={closeMobileNavModal}>
                <div className=''>
                  <div className='fixed inset-0 bg-black bg-opacity-70' />
                  <div className='fixed inset-0'>
                    <div className='relative z-[10000] w-full transition-all'>
                      <div className='container relative mx-auto max-h-[95%] overflow-y-auto rounded-2xl bg-slate-900 px-4 py-4 shadow-xl transition-all xs:px-8 sm:px-6 md:py-9 lg:py-4'>
                        <div
                          ref={mobileNavRef}
                          className={`transform pb-4 text-left align-middle font-sans text-white transition-all`}
                        >
                          <div className='flex w-full flex-row items-center justify-between'>
                            <Link href='/en/' aria-label='Komodo logo' className='mr-4'>
                              <Logo />
                            </Link>

                            <button
                              type='button'
                              className='text-blue-200 hover:text-blue-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
                              onClick={closeMobileNavModal}
                            >
                              <XCircleIcon className='h-8 w-8' />
                            </button>
                          </div>
                          <ul className='mt-4 space-y-1'>
                            <li className='pb-4'>
                              <Link
                                href='/en/developer/'
                                onClick={closeMobileNavModal}
                                className='px-2 text-lg font-medium'
                              >
                                Developer
                              </Link>
                            </li>
                            <li className='pb-4'>
                              <Link
                                href='/en/community/'
                                onClick={closeMobileNavModal}
                                className='px-2 text-lg font-medium'
                              >
                                Community
                              </Link>
                            </li>
                            {/* <li className='pb-4'>
                            <Link href='#' className='px-2 text-lg font-medium'>
                              White paper
                            </Link>
                          </li> */}
                            <li className='pb-4'>
                              <Link
                                href='/en/blog/'
                                onClick={closeMobileNavModal}
                                className='px-2 text-lg font-medium'
                              >
                                News
                              </Link>
                            </li>
                            <li className='pb-4'>
                              <Link
                                href='/en/wallet/'
                                onClick={closeMobileNavModal}
                                className='px-2 text-lg font-medium'
                              >
                                Downloads
                              </Link>
                            </li>
                            {/* {superNavData.map((item: any, index: number) => {
                            return (
                              <MobileSuperNavItem
                                catName={item.title}
                                items={item.items}
                                key={index}
                              />
                            );
                          })} */}
                            {/* <li className="pb-4">
                            <Link
                              href="/en/contact/"
                              className="px-2 text-lg font-medium"
                            >
                              Contact
                            </Link>
                          </li> */}
                            <li className='pb-4'>
                              <Link
                                href='https://github.com/komodoplatform/'
                                target='_blank'
                                className='flex flex-row items-center px-2'
                                onClick={closeMobileNavModal}
                              >
                                <NavGithubLogo className='h-6 w-6' />
                                <span className='ml-2 text-lg font-medium'>Github</span>
                              </Link>
                            </li>
                            <li className='block lg:hidden' onClick={closeMobileNavModal}>
                              <KomodoWalletBtnNav />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};
